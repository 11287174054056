body {
    font-family: 'Open Sans', sans-serif;
    padding: 20px 60px;
    background: whitesmoke;
}

a {
    text-decoration: none;
    color: black;
}

* {
    box-sizing: border-box;
}